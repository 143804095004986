import { useState, useEffect, useRef } from 'react'

import './SelectPaginated.css'

import FetchHelper from '../helpers/fetch'
import PaginationComponent from '../components/Pagination'

import useAppContext from '../hooks/useAppContext'

const SelectPaginated = ({ apiUrl, onSelect, titleFilter, initialValue, readOnly, offerAllOption = false, allValue = null, queries }) => {
    const getDefaultSelectedItem = () => {
        return { title: offerAllOption ? 'All' : '--', value: allValue, item: null }
    }

    const [ac] = useAppContext()

    const [suggestions, setSuggestions] = useState()
    const [selectedOption, setSelectedOption] = useState(initialValue || getDefaultSelectedItem())
    const [pages, setPages] = useState(1)
    const [term, setTerm] = useState()
    const [page, setPage] = useState(1)
    const [showAutocomplete, setShowAutocomplete] = useState(false)
    const [styleContainer, setStyleContainer] = useState({ top: 0, left: 0, width: 0 })
    const [isLoading, setIsLoading] = useState(false)
    const [target, setTarget] = useState()
    const [fetchedInitial, setFetchedInitial] = useState(false)

    useEffect(() => {
        if (initialValue === undefined || initialValue === null || initialValue === '') {
            setSelectedOption(getDefaultSelectedItem())
            return;
        }

        if (!fetchedInitial && apiUrl && initialValue && typeof initialValue === 'string') {
            setFetchedInitial(true)

            FetchHelper({
                url: `${apiUrl}/${initialValue}`
            }, (res) => {
                let entity = res.body

                setSelectedOption({ title: entity.name, value: entity.id, item: entity })
            })
        }
    }, [initialValue])

    useEffect(() => {
        if (!/null|undefined/.test(term) && page) {
            setIsLoading(true)

            let url = `${apiUrl}?q=${term}&page=${page}`
            if (queries && queries.length > 0) {
                queries.forEach((query) => {
                    url += `&${query.key}=${query.value}`
                })
            }
            FetchHelper({ url }, (res) => {
                setPages(res.body.total_pages)
                
                let suggestions = res.body.data.map((item) => {
                    let name = titleFilter ? titleFilter(item) : item.name

                    return {
                        value: item.id,
                        title: name,
                        item: item
                    }
                });
                if(offerAllOption && page === 1) suggestions.unshift({ value: allValue, title: 'All', item: null })
                setSuggestions(suggestions)
                setIsLoading(false)
            }, (res) => {
                setIsLoading(false)
            })
        }
    }, [term, page])

    useEffect(() => {
        setPage(1) // reset page to 1 when query changes
    }, [queries])

    const selectOption = (option) => {
        setSelectedOption(option)
        hideAutocomplete()

        if (selectedOption !== null) {
            onSelect(option)
        }
    }

    const displayAutocomplete = () => {
        // ac.setIsScrollDisabled(true)
        setShowAutocomplete(true)
        setTerm('')
    }

    const hideAutocomplete = (e) => {
        setShowAutocomplete(false)
        ac.setDisplayOverlay()
    }

    const renderSuggestions = () => {
        let matches = suggestions

        if (term !== '') {
            matches = (suggestions || []).filter((suggestion) => {
                return suggestion.title.toLowerCase().indexOf(term.toLowerCase()) > -1
            })
        }

        return (
            <div className="select-paginated-suggestions">
                {matches && matches.map((option) => {
                    return (
                        <div className="select-paginated-option" key={`select-paginated-suggestion-${option.value}`} onClick={(e) => {
                            selectOption(option)
                        }}>{option.title}</div>
                    )
                }) || null}
            </div>
        )
    }

    const handleClick = (e) => {
        setTarget(e.target.closest('.select-paginated-container'))

        displayAutocomplete()
    }

    useEffect(() => {
        if (showAutocomplete) {
            ac.setDisplayOverlay({
                content: () => {
                    return (
                        <div className="select-paginated-dropdown" onClick={(e) => e.stopPropagation()}>
                            <input type="text" className="select-paginated-dropdown-input" value={term} autoFocus onChange={(e) => {
                                setTerm(e.target.value)
                                setPage(1)
                            }} placeholder="Search..." readOnly={readOnly} />

                            {isLoading ? (
                                <div className="loading"></div>
                            ) : renderSuggestions()}

                            <PaginationComponent currentPage={page} totalPages={pages} setPage={setPage} radius={2} />
                        </div>
                    )
                },
                target: target,
                style: {
                    width: 280,
                },
                onClose: () => {
                    hideAutocomplete()
                }
            })
        }
    }, [showAutocomplete, term, page, isLoading])

    if (!selectedOption) {
        return null
    } else {
        let containerClass = 'select-paginated-container'

        if (readOnly)
            containerClass += ' disabled'

        return (
            <div className={containerClass}>
                <div className="select-paginated-title" onClick={handleClick}><span>{selectedOption.title}</span> <i className="bi bi-chevron-down"></i></div>
            </div>
        )
    }
}

export default SelectPaginated
