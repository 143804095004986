import {useState, useEffect} from 'react'

import ControlBar from '../../components/ControlBar'
import FetchHelper from '../../helpers/fetch'
import FormComponent from '../../components/Form'
import PageHeaderComponent from '../../components/PageHeader'
import ProjectorComponent from '../../components/Projector'

import RouteHelper from '../../helpers/route'

import useAppContext from '../../hooks/useAppContext'
import useNotices from '../../hooks/useNotices'
import useTabs from '../../hooks/useTabs'
import UtilsHelper from '../../helpers/utils'

import './index.css'
import { FileTypes } from '../../sys/constants/enums'
import lodash from 'lodash'
import { getGlobalFormFields } from './providers/form-fields'

const SettingsPage = () => {
    const [ac] = useAppContext()

    const [NoticeComponent, notices] = useNotices('Tools/Settings')

    // TABS
    const [tabsMain, tabMain, setTabMain, TabsComponent] = useTabs('Global', ['Global', 'Confirmation Number', 'Global Expiration', 'Theme Configuration'])
    const [tabsTheme, tabTheme, setTabTheme] = useTabs('Generic', ['Bunny', 'Dance', 'Santa', 'Generic'])

    // FORM
    const [errors, setErrors] = useState()

    // --- form fields
    const [globalFormFields, setGlobalFormFields] = useState(getGlobalFormFields())

    // DATA
    const [data, setData] = useState()
    const [dataSettings, setDataSettings] = useState()
    const [dataExpiration, setDataExpiration] = useState()
    const [dataGlobal, setDataGlobal] = useState()
    const [dataThemeDefault, setDataThemeDefault] = useState('generic')
    const [dataThemeBunny, setDataThemeBunny] = useState()
    const [dataThemeDance, setDataThemeDance] = useState()
    const [dataThemeSanta, setDataThemeSanta] = useState()
    const [dataThemeGeneric, setDataThemeGeneric] = useState()

    useEffect(() => {
        ac.showSpinner(true)

        FetchHelper({
            url: `/api/v2/admin/settings`
        }, (res) => {
            ac.showSpinner(false)

            let body = res.body

            setData(body)

            setDataSettings({
                use_fastpass_range: body.use_fastpass_range === 'yes' ? true : false,
                fastpass_range_consumed_action: body.fastpass_range_consumed_action,
                fastpass_range_start: body.fastpass_range_start,
                fastpass_range_end: body.fastpass_range_end
            })

            setDataExpiration({
                exp_date_time: body.expiration_date,
                reservation_time_limit: body.reservation_time_limit,
                walkup_time_limit: body.walkup_time_limit
            })

            setDataGlobal({
                watermark_url: body.watermark_url,
                policy_description: body.policy_description,
                policy_url: body.policy_url,
                ga_tracking_id: body.third_party?.ga_tracking_id,
                gtm_container_id: body.third_party?.gtm_container_id,
                email_friendly_from: body.email_friendly_from,
                customer_service_email: UtilsHelper.stringifyEmails(body.customer_service_email),
                api_access_token: body.api_access_token,
                verification_token: body.verification_token,
                twilio_service_numbers: body.third_party?.twilio_service_numbers,
                zapier_service_enabled: body.third_party?.zapier_service_enabled,
                zapier_service_url: body.third_party?.zapier_service_url,
                // zoom_service_api_key: body.zoom_service_api_key,
                // zoom_service_api_secret: body.zoom_service_api_secret,
                portable_north_pole_service_username: body.third_party?.portable_north_pole_service_username,
                portable_north_pole_service_password: body.third_party?.portable_north_pole_service_password,
                lock_no_shows: body.lock_no_shows,
                appointment_aware_refunding: body.appointment_aware_refunding,
                appointment_aware_refunding_percentage_0: body.appointment_aware_refund_schedule[0].percentage,
                appointment_aware_refunding_limit_0: body.appointment_aware_refund_schedule[0].limit,
                appointment_aware_refunding_percentage_1: body.appointment_aware_refund_schedule[1].percentage,
                appointment_aware_refunding_limit_1: body.appointment_aware_refund_schedule[1].limit,
                appointment_aware_refunding_percentage_2: body.appointment_aware_refund_schedule[2].percentage,
                appointment_aware_refunding_limit_2: body.appointment_aware_refund_schedule[2].limit,
                appointment_aware_refunding_percentage_3: body.appointment_aware_refund_schedule[3].percentage,
                appointment_aware_refunding_limit_3: body.appointment_aware_refund_schedule[3].limit,
                appointment_aware_refunding_percentage_4: body.appointment_aware_refund_schedule[4].percentage,
                appointment_aware_refunding_limit_4: body.appointment_aware_refund_schedule[4].limit,
            })

            const generateThemeConfig = (themeName) => {
                return {
                    market_name: body.theme[themeName].market_name,
                    color_scheme_id: body.theme[themeName].color_scheme_id,
                    logo: body.theme[themeName].logo,
                    use_email_barcode: body.theme[themeName].use_email_barcode,
                    confirm_market_choice: body.theme[themeName].confirm_market_choice,
                    event_listing_search_placeholder: body.theme[themeName].event_listing_search_placeholder,
                    event_listing_information: body.theme[themeName].event_listing_information,
                    proof_search_information: body.theme[themeName].proof_search_information,
                    footer: body.theme[themeName].footer,
                    timeslots_full_message: body.theme[themeName].timeslots_full_message
                }
            }

            setDataThemeGeneric(generateThemeConfig('generic'))
            setDataThemeBunny(generateThemeConfig('bunny'))
            setDataThemeSanta(generateThemeConfig('santa'))
            setDataThemeDance(generateThemeConfig('dance'))
        }, (res) => {
            ac.showSpinner(false)
        })
    }, [])

    useEffect(() => {
        let fields = getGlobalFormFields(ac, dataGlobal, data)
        fields = lodash.values(lodash.merge(lodash.keyBy(fields, 'name'), lodash.keyBy(globalFormFields, 'name')))
        setGlobalFormFields(fields)
    }, [ac, dataGlobal, data?.third_party?.twilio_service_sid, data?.third_party?.twilio_service_auth_token])

    const handleSubmit = () => {
        ac.showSpinner(true);

        let fieldsToConsiderByKey = lodash
            .chain(globalFormFields)
            .filter(field => field.readonly !== true)
            .keyBy("name")
            .value()
        let globalData = lodash
            .pickBy(
                dataGlobal,
                (_, key) => lodash.has(fieldsToConsiderByKey, key)
            )

        let body = {
            ...dataSettings,
            ...dataExpiration,
            ...globalData,
            customer_service_email: UtilsHelper.stringifyEmails(dataGlobal?.customer_service_email),
            default_theme: dataThemeDefault,
            theme_configs: {
                bunny: dataThemeBunny,
                dance: dataThemeDance,
                santa: dataThemeSanta,
                generic: dataThemeGeneric
            }
        }

        body.use_fastpass_range = body.use_fastpass_range ? 'yes' : 'no'

        if (body.watermark) {
          body.watermark_base64 = body.watermark.value
        }

        ['santa', 'bunny', 'dance', 'generic'].forEach((theme) => {
          if (body.theme_configs[theme].logo_attributes)
            body.theme_configs[theme].logo_attributes = {
              data_base64: body.theme_configs[theme].logo_attributes.value
            };

          delete body.theme_configs[theme].logo
          delete body.theme_configs[theme].logo_attributes
        });

        if (globalData.appointment_aware_refunding) {
            body.appointment_aware_refund_schedule = [];

            for (let i = 0; i < 5; i++) {
                body.appointment_aware_refund_schedule.push({
                    limit: dataGlobal[`appointment_aware_refunding_limit_${i}`],
                    percentage: dataGlobal[`appointment_aware_refunding_percentage_${i}`],
                })
            }
        }

        // Cleanup
        delete body.watermark
        delete body.api_access_token
        delete body.verification_token

        FetchHelper({
            url: `/api/v2/admin/settings`,
            method: 'put',
            body: {
                settings: body
            }
        }, (res) => {
            ac.showSpinner(false)
            setData(res.body)
            // TODO: cleaner "success" state.
            RouteHelper.redirect('/settings')
        }, (res) => {
            ac.showSpinner(false)
        })
    }

    const handleResetDefault = (type) => {
        setDataThemeBunny({
            ...dataThemeBunny,
            default_theme: 'false'
        })

        setDataThemeDance({
            ...dataThemeDance,
            default_theme: 'false'
        })

        setDataThemeSanta({
            ...dataThemeSanta,
            default_theme: 'false'
        })

        setDataThemeGeneric({
            ...dataThemeGeneric,
            default_theme: 'false'
        })
    }

    const generateThemeFormConfig = (theme) => {
        return [
            {
                type: 'input-text',
                name: 'market_name',
                label: 'Market Name',
            },
            {
                type: 'select-paginated',
                name: 'color_scheme_id',
                label: 'Color Scheme',
                apiUrl: '/api/v2/admin/color-schemes',
            },
            {
                type: 'switch',
                name: 'use_email_barcode',
                label: 'Replace bottom barcode with email barcode?',
            },
            {
                type: 'input-file',
                name: 'logo_attributes',
                label: 'Logo',
                acceptedFileTypes: FileTypes.IMAGES,
                label_info: 'This logo will be overridden if there is a logo at the market level',
                imageUrl: theme?.logo?.thumbnail_url?.match(/missing\.png/) ? null : theme?.logo?.thumbnail_url
            },
            {
                type: 'switch',
                name: "confirm_market_choice",
                label: "Confirm market choice",
                label_info: "Enabling this option will prompt the customer to confirm their market choice upon making a selection on the event listing, zip search, and location pages"
            },
            {
                type: 'input-text',
                name: 'event_listing_search_placeholder',
                label: 'Event Listing Search Placeholder',
            },
            {
                type: 'editor',
                name: 'event_listing_information',
                label: 'Event Listing Information',
            },
            {
                type: 'editor',
                name: 'proof_search_information',
                label: 'Proof Search Information',
            },
            {
                type: 'editor',
                name: 'footer',
                label: 'Footer',
            },
            {
                type: 'editor',
                name: 'timeslots_full_message',
                label: 'Timeslots Full Message',
            }
        ]
    }

    const renderDefaultField = (type) => {
        if (type === dataThemeDefault) {
            return (
                <div className="default-message active">This theme is marked as default</div>
            )
        } else {
            return (
                <div className="default-message"><button className="primary" onClick={() => setDataThemeDefault(type)}>Set Default</button></div>
            )
        }

    }

    return (
        <div className="settings-page-container page-container">
            <PageHeaderComponent title={`Settings`} />

            <TabsComponent tabs={tabsMain} tab={tabMain} setTab={setTabMain} />

            {data ? (
                <>
                    <div className={`page-form-container global-tab ${tabMain === 'Confirmation Number' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/ConfirmationNumber" notices={notices} />
                        <FormComponent formData={dataSettings} formErrors={errors} onChange={setDataSettings} displayErrorBanner={true} fields={[
                            {
                                type: 'switch',
                                name: 'use_fastpass_range',
                                label: 'Use custom range?',
                            },
                            {
                                type: 'select',
                                name: 'fastpass_range_consumed_action',
                                label: 'If upper limit is reached',
                                value: 'repeat',
                                options: [
                                    {
                                        value: 'repeat',
                                        title: 'Repeat Range'
                                    },
                                    {
                                        value: 'fallback',
                                        title: 'Fallback to Default'
                                    },

                                ]
                            },
                            {
                                type: 'group',
                                label: 'Range',
                                fields: [
                                    {
                                        type: 'input-number',
                                        name: 'fastpass_range_start',
                                    },
                                    {
                                        type: 'html',
                                        html: <i className="bi bi-dash-lg"></i>
                                    },
                                    {
                                        type: 'input-number',
                                        name: 'fastpass_range_end'
                                    },
                                ]
                            }
                        ]} />
                    </div>

                    <div className={`page-form-container global-tab ${tabMain === 'Global Expiration' ? 'show' : ''}`}>
                        <NoticeComponent location="Tab/GlobalExpiration" notices={notices} />
                        <FormComponent formData={dataExpiration} formErrors={errors} onChange={setDataExpiration} displayErrorBanner={true} fields={[
                            {
                                type: 'datetime',
                                name: 'exp_date_time',
                                label: 'Expiration Date',
                            },
                            {
                                type: 'input-text',
                                name: 'reservation_time_limit',
                                label: 'Reservation Time Limit (seconds)'
                            },
                            {
                                type: 'input-text',
                                name: 'walkup_time_limit',
                                label: 'Walkup Time Limit (seconds)'
                            },
                        ]} />
                    </div>

                    <div className={`page-form-container global-tab ${tabMain === 'Global' ? 'show' : ''}`}>
                        {/* <ProjectorComponent url={data?.image?.image_url} /> */}
                        <NoticeComponent location="Tab/Global" notices={notices} />
                        <FormComponent formData={dataGlobal} formErrors={errors} onChange={setDataGlobal} displayErrorBanner={true} fields={globalFormFields} setFields={setGlobalFormFields} />
                    </div>

                    <div className={`page-form-container global-tab ${tabMain === 'Theme Configuration' ? 'show' : ''}`}>
                        <TabsComponent tabs={tabsTheme} tab={tabTheme} setTab={setTabTheme} />

                        <div className={`page-form-container global-tab ${tabTheme === 'Bunny' ? 'show' : ''}`}>
                            {/* <ProjectorComponent url={dataThemeBunny?.image?.image_url} /> */}
                            {renderDefaultField('bunny')}
                            <NoticeComponent location="Tab/ThemeConfiguration/Bunny" notices={notices} />
                            <FormComponent formData={dataThemeBunny} formErrors={errors} formKey="settings-theme-form-bunny" onChange={setDataThemeBunny} displayErrorBanner={true} fields={generateThemeFormConfig(dataThemeBunny)} />
                        </div>

                        <div className={`page-form-container global-tab ${tabTheme === 'Dance' ? 'show' : ''}`}>
                            {/* <ProjectorComponent url={dataThemeDance?.image?.image_url} /> */}
                            {renderDefaultField('dance')}
                            <NoticeComponent location="Tab/ThemeConfiguration/Dance" notices={notices} />
                            <FormComponent formData={dataThemeDance} formErrors={errors} formKey="settings-theme-form-dance" onChange={setDataThemeDance} displayErrorBanner={true} fields={generateThemeFormConfig(dataThemeDance)} />
                        </div>

                        <div className={`page-form-container global-tab ${tabTheme === 'Santa' ? 'show' : ''}`}>
                            {/* <ProjectorComponent url={dataThemeSanta?.image?.image_url} /> */}
                            {renderDefaultField('santa')}
                            <NoticeComponent location="Tab/ThemeConfiguration/Santa" notices={notices} />
                            <FormComponent formData={dataThemeSanta} formErrors={errors} formKey="settings-theme-form-santa" onChange={setDataThemeSanta} displayErrorBanner={true} fields={generateThemeFormConfig(dataThemeSanta)} />
                        </div>

                        <div className={`page-form-container global-tab ${tabTheme === 'Generic' ? 'show' : ''}`}>
                            {/* <ProjectorComponent url={dataThemeGeneric?.image?.image_url} /> */}
                            {renderDefaultField('generic')}
                            <NoticeComponent location="Tab/ThemeConfiguration/Generic" notices={notices} />
                            <FormComponent formData={dataThemeGeneric} formErrors={errors} formKey="settings-theme-form-generic" onChange={setDataThemeGeneric} displayErrorBanner={true} fields={generateThemeFormConfig(dataThemeGeneric)} />
                        </div>
                    </div>
                </>
            ) : null}

            <ControlBar data={data} handleSubmit={handleSubmit} cancelUrl="/" enableCopy={false} enableDelete={false} />
        </div>
    )
}

export default SettingsPage