import { useState, useEffect, forwardRef, useImperativeHandle } from 'react'

import TagsInput from './TagsInput'
import ColorPicker from './ColorPicker'
import EntitySelectorComponent from './EntitySelector'
import FileUploader from './FileUploader'
import InputAutocomplete from './InputAutocomplete'
import TwoSideMultiselect from './TwoSideMultiselect'
import CopyButton from './CopyButton'
import Tooltip from './Tooltip'
import SelectPaginated from './SelectPaginated'
import SwitchComponent from './Switch'
import PickerDate from './PickerDate'
import PickerDateTime from './PickerDateTime'
import PickerTime from './PickerTime'
import HTMLEditor from './HTMLEditor'
import lodash from 'lodash'

import useWindowDimensions from '../hooks/useWindowDimensions'

import './Form.css'
import useAppContext from '../hooks/useAppContext'
import useIsFirstRender from '../hooks/useIsFirstRender'
import { HTMLEditorMode } from '../sys/constants/enums'

const FormComponent = forwardRef(({ tab, fields = [], setFields, onChange, formData, formKey='', formErrors, setFormErrors, displayErrorBanner, type, componentClassName='', onFormErrorsUpdated = (errorsCount) => {} }, ref) => {
    const [ac] = useAppContext();
    const [data, setData] = useState({})
    const [formErrorsData, setFormErrorsData] = useState({ errorCounts: 0, errorFieldsKeys: [], errorFieldsLabels: [] })
    const isFirstRender = useIsFirstRender();

    const { height, width } = useWindowDimensions();

    const getDefaultValue = (field) => {
        let val = field.value || field.defaultValue

        if (val !== undefined) return val

        switch (field.type) {
            case 'input-number':
            case 'input-money':
                val = 0
                break
            case 'checkbox':
            case 'switch':
                val = false
                break
            default:
                val = ''
        }

        return val
    }

    const getValue = (data, field) => {
        return /null|undefined/.test(data[field.name]) ? '' : (field.type === 'select') ? String(data[field.name]) : data[field.name]
    }

    const getToggleField = (field) => {
        return { ...field, name: `${field.name}_toggle`, type: 'switch', readonly: false }
    }

    // --- clear all selected fields (eg. on markets bulk edit modal, selected = toggled)
    const clearAllSelected = () => {
        let newState = { ...data }

        lodash
            .chain(fields)
            .filter(field => !field.readonly)
            .each(field => {
                if(!field.name) return;

                let defaultValue = getDefaultValue({ ...field, value: undefined })
                newState[field.name] = defaultValue
            })
            .value()
        setData(newState)
    }

    useEffect(() => {
        let newState = {}

        fields.forEach((field) => {
            if (field.type === 'group') {
                field.fields.forEach((f) => {
                    newState[f.name] = formData ? getValue(formData, f) : getDefaultValue(f)
                })
            } else if (field.type !== 'title' && field.name) {
                newState[field.name] = formData ? getValue(formData, field) : getDefaultValue(field)
            }

            if (field.canToggle) {
                let toggleField = getToggleField(field)
                let defaultValue = false;
                let value = formData ? getValue(formData, toggleField) : defaultValue
                if (value === '') value = defaultValue
                newState[toggleField.name] = value;
            }
        })

        setData(newState)
    }, [tab])

    useEffect(() => {
        if(isFirstRender) return;

        onChange(data)
    }, [data])

    useEffect(() => {
        let shouldUpdateFields = false;

        let updatedFields = fields.map(field => {
            if (field.canToggle) {
                let toggleValue = data[`${field.name}_toggle`];
                if (toggleValue !== undefined && toggleValue !== null) {
                    let correctReadOnly = toggleValue !== true;
                    let currentReadOnly = field.readonly;
                    if (correctReadOnly !== currentReadOnly) {
                        field.readonly = correctReadOnly;
                        shouldUpdateFields = true;
                    }
                }
            }
            return field;
        })

        if (shouldUpdateFields) setFields(updatedFields)
    }, [data])

    const onBlurInput = (event, field) => {
        if (field.type === 'input-money') {
            const value = event.target.value
            if(value === null || value === undefined || value === '') return;
            
            // format input value
            const formattedValue = Number(value).toFixed(2)
            onChangeInput(formattedValue, field)
        }
    }

    const onChangeInput = (value, field) => {
        if (formErrors && formErrors[field.name]) {
            let newFormErrors = { ...formErrors }
            delete newFormErrors[field.name]
            if(setFormErrors) setFormErrors(newFormErrors)
            else delete formErrors[field.name]
        }

        const newState = {
            ...data,
            [field.name]: field.type === 'checkbox' || field.type === 'switch' ? !data[field.name] : value
        }

        if (field.filter) {
            newState[field.name] = field.filter(newState[field.name])
        }

        if (field.onChange) {
            field.onChange(newState)
        }

        setData(newState)
    }

    const onClearInput = (e, field) => {
        let defaultValue = getDefaultValue({ ...field, value: undefined })

        if (field.type === 'wysiwyg') {
            if (field.editor)
                field.editor.setData(defaultValue)
            return;
        }

        onChangeInput(defaultValue, field)
    }

    const callOrReturnValue = (value) => {
      if (typeof value === 'function')
        return value(data)
      else
        return value
    }

    const renderFormField = (field) => {
        switch (field.type) {
            case 'html':
                return field.html
            case 'input-text':
                return <input className="form-input input-type-text" type="text" name={field.name} value={data[field.name] || ''} placeholder={field.placeholder || ''} onChange={(e) => onChangeInput(e.target.value, field)} disabled={callOrReturnValue(field.readonly)} />
            case 'input-password':
                return <input className="form-input input-type-password" type="password" name={field.name} value={data[field.name] || ''} placeholder={field.placeholder || ''} onChange={(e) => onChangeInput(e.target.value, field)} readOnly={callOrReturnValue(field.readonly)} />
            case 'input-number':
                return <input className="form-input input-type-number" type="number" name={field.name} value={data[field.name] || 0} placeholder={field.placeholder || ''} step={field.step || 'any'} min={field.min} max={field.max} onChange={(e) => onChangeInput(e.target.value, field)} disabled={callOrReturnValue(field.readonly)} />
            case 'input-percent':
                return (
                    <div className='input-percent'>
                        <input type="number" name={field.name} value={data[field.name] || ''} placeholder={field.placeholder || ''} step={field.step || 'any'} min={field.min} max={field.max} onChange={(e) => onChangeInput(e.target.value, field)} readOnly={callOrReturnValue(field.readonly)} />
                        <span className='input-span'>%</span>
                    </div>
                )
            case 'input-money':
                return (
                    <div className='input-money'>
                        <span className='input-span'>$</span>
                        <input type="number" name={field.name} value={(data[field.name] === undefined || data[field.name] === null) ? '' : data[field.name]} placeholder={field.placeholder || ''} step={field.step || 1} min={field.min} max={field.max} onChange={(e) => onChangeInput(e.target.value, field)} disabled={callOrReturnValue(field.readonly)} 
                            onBlur={e => onBlurInput(e, field)} />
                    </div>
                )
            case 'input-time':
                return (
                    <div className='input-time'>
                        {field.symbol ? <span className='input-span'>{field.symbol}</span> : <span className='no-symbol'></span>}
                        <input type="number" className={!field.symbol ? 'no-symbol' : ''} name={field.name} value={data[field.name] || ''} placeholder={field.placeholder || ''} onChange={(e) => onChangeInput(e.target.value, field)} readOnly={callOrReturnValue(field.readonly)} />
                        <span className='input-span'>{field.tag}</span>
                    </div>
                )
            case 'checkbox':
                let boxClass = callOrReturnValue(field.readonly) ? 'disabled' : null

                // NOTE: checkboxes cannot be "readonly" and disallow interaction.
                return <input type="checkbox" className={`form-input input-type-checkbox ${boxClass}`} name={field.name} value={data[field.name] || false} checked={data[field.name] || false} onChange={(e) => onChangeInput(e.target.value, field)} disabled={callOrReturnValue(field.readonly)} />
            case 'switch':
                return (
                    <>
                        <SwitchComponent value={data[field.name] || false} onChange={(value) => onChangeInput(value, field)} disabled={callOrReturnValue(field.readonly)} />
                        {field.field_label}
                    </>
                )
            case 'radio':
                return (
                    <ul className="radio">
                        {field.options && field.options.map((option, index) => {
                            return (
                                <li key={`form-field-radio-options-${option.value}-${index}`}><input className="form-input input-type-radio" type="radio" name={field.name} value={option.value || ''} checked={option.value === data[field.name]} onChange={(e) => onChangeInput(e.target.value, field)} /> <span className="radio-label">{option.label}</span> {renderTooltip(option)}</li>
                            )
                        })}
                    </ul>
                )
            case 'input-file':
                return <FileUploader
                    field={field}
                    value={data[field.name] || ''}
                    onChange={(value) => onChangeInput(value, field)}
                    readOnly={callOrReturnValue(field.readonly)}
                    fileType={field.fileType}
                    imageUrl={field.imageUrl}
                    outputFormat={field.outputFormat || undefined}
                    acceptedFileTypes={field.acceptedFileTypes}
                    multiple={field.multiple} />
            case 'textarea':
                return <textarea className="form-input input-type-textarea" value={data[field.name] || ''} placeholder={field.placeholder || ''} onChange={(e) => onChangeInput(e.target.value, field)} readOnly={callOrReturnValue(field.readonly)} />
            case 'select':
                return (
                    <select className="form-input input-type-select" name={field.name} value={data[field.name]} onChange={(e) => onChangeInput(e.target.value, field)} readOnly={callOrReturnValue(field.readonly)} disabled={callOrReturnValue(field.readonly)}>
                        {field.options && field.options.map((item, idx) => {
                            if (!item.group)
                                return (<option key={`${field.name}-${idx}-${item.value}`} value={item.value}>{item.title}</option>)

                            return (
                                <optgroup label={item.group} key={`${field.name}-opt-group-${idx}`}>
                                    {item.options && item.options.map((option) => {
                                        return <option key={`${field.name}-${idx}-${option.value}`} value={option.value}>{option.title}</option>
                                    })}
                                </optgroup>
                            )
                         })}
                    </select>
                )
            case 'select-multiple':
                return (
                    <select className="form-input input-type-select-multiple" multiple="multiple" name={field.name} value={data[field.name] || []} onChange={(e) => onChangeInput(e.target.value, field)} readOnly={callOrReturnValue(field.readonly)}>
                        {field.options && field.options.map((option) => {
                            return (
                                <option key={`${field.name}-${option.value}`} value={option.value || ''}>{option.title}</option>
                            )
                        })}
                    </select>
                )
            case 'select-paginated':
                return (
                    <SelectPaginated apiUrl={field.apiUrl} titleFilter={field.titleFilter} initialValue={data[field.name]} onSelect={(value) => onChangeInput(field.onChangeFilter ? field.onChangeFilter(value) : value.value, field)} readOnly={callOrReturnValue(field.readonly)} queries={field.queries} />
                )
            case 'entity-selector':
                return (
                    <EntitySelectorComponent entityType={field.entityType} entityData={data[field.name]} entityTitleFilter={field.entityTitleFilter} onChange={(value) => onChangeInput(value, field)} />
                )
            case 'datetime':
                return <PickerDateTime initialValue={data[field.name]} onChange={(value) => onChangeInput(value, field)} dateFormat="YYYY-MM-DDTHH:mm" readOnly={callOrReturnValue(field.readonly)} />
            case 'date':
                return <PickerDate initialValue={data[field.name]} onChange={(value) => onChangeInput(value, field)} dateFormat="YYYY-MM-DD" readOnly={callOrReturnValue(field.readonly)} />
            case 'time':
                return <PickerTime initialValue={data[field.name]} onChange={(value) => onChangeInput(value, field)} readOnly={callOrReturnValue(field.readonly)} />
            case 'editor':
            case 'wysiwyg':
                return <HTMLEditor initialValue={data[field.name]} onChange={(value) => onChangeInput(value, field)} readOnly={callOrReturnValue(field.readonly)} key={`htmleditor-${field.name}`}
                    onEditorReady={(editor) => { field.editor = editor }} mode={ field.editorMode || HTMLEditorMode.NORMAL } showTags={ field.showTags || false } title={field.label}/>
            case 'tags':
                return <TagsInput
                    value={data[field.name] || ''}
                    suggestions={field.suggestions}
                    onDelete={(value) => onChangeInput(value, field)}
                    onAdd={(value) => onChangeInput(value, field)}
                    readOnly={callOrReturnValue(field.readonly)} />
            case 'input-autocomplete':
                return <InputAutocomplete
                    value={data[field.name] || ''}
                    suggestions={field.suggestions}
                    onChange={(value) => onChangeInput(value, field)}
                    readOnly={callOrReturnValue(field.readonly)} />
            case 'two-multiselect':
                return <TwoSideMultiselect
                    labels={field.list_labels}
                    options={field.selectable}
                    value={field.selected || ''}
                    onChange={(value) => onChangeInput(value, field)}
                    readOnly={callOrReturnValue(field.readonly)} />
            case 'color-picker':
                return <ColorPicker
                    type={field.color_type}
                    name={field.name}
                    data={data[field.name]}
                    onChange={(value) => onChangeInput(value, field)}
                    readOnly={callOrReturnValue(field.readonly)} />
            case 'copy-button':
                return <CopyButton value={data[field.name] || ''} />
            default:
                break;
        }
    }

    const hasErrors = () => {
        return Object.keys(formErrors || {}).length > 0
    }

    const isFieldError = (field) => {
        return (formErrors && formErrors[field.name] || []).length > 0
    }

    const renderFormFieldError = (field) => {
        const e = formErrors && formErrors[field.name]

        if (!e)
            return null

        return (
            <div className="form-field-error">
                {e.map((error, i) => {
                    return <div className={`form-field-error-${field.name}-${i}`} key={`form-field-error-${field.name}-${i}`}>{error}</div>
                })}
            </div>
        )
    }

    const getFormErrorsData = () => {
        let errorCounts = 0;
        
        let fieldsByKey = lodash.keyBy(fields, 'name')
        let errorFieldsKeys = lodash
            .chain(formErrors || {})
            .keys()
            .filter(key => fieldsByKey[key])
            .value()

        errorCounts = errorFieldsKeys.length

        let errorFieldsLabels = errorFieldsKeys.map(key => fieldsByKey[key]?.label || key)

        let errorsData = { errorFieldsKeys, errorFieldsLabels, errorCounts }
        if(!lodash.isEqual(errorsData, formErrorsData))
            setFormErrorsData(errorsData)
        return errorsData;
    }

    useEffect(() => {
        let { errorCounts } = formErrorsData
        onFormErrorsUpdated(errorCounts)
    }, [formErrorsData.errorCounts])

    const renderFormErrors = () => {
        if (!displayErrorBanner) return null

        let errorsData = getFormErrorsData()
        let { errorFieldsLabels, errorCounts } = errorsData;
        if (errorCounts === 0) return null;

        return (
            <div className="form-errors">
                <div className="form-errors-title">Please address errors for the following fields:</div>
                <div className="form-errors-list">{errorFieldsLabels.join(', ')}</div>
            </div>
        )
    }

    const renderTooltip = (field) => {
        const showDivider = field.label_info && field.field_info

        if (field.label_info || field.field_info) {
            return (
                <Tooltip>
                    {field.label_info}
                    {showDivider ? <hr /> : null}
                    {field.field_info}
                </Tooltip>
            )
        } else {
            return null
        }
    }

    const renderFieldDescription = (field) => {
        if (field.description) {
            return <div>{field.description}</div>
        } else {
            return null
        }
    }

    const renderFieldInputDescription = (field) => {
        if (field.inputDescription) {
            return <>{field.inputDescription}</>
        } else {
            return null
        }
    }

    let filteredFields = fields.filter((field, index) => {
        if (typeof field.condition == 'boolean')
            return field.condition;

        return field.condition ? field.condition(data, ac) : true;
    })

    useImperativeHandle(ref, () => {
        return {
            clearAllSelected: clearAllSelected
        }
    })

    return (
        <div className={`form-container ${type ? type : width < 1024 ? 'stacked' : 'default'} ${componentClassName}`}>
            {renderFormErrors()}
            <div className="form-fields">
                {data && filteredFields.map((field, i) => {
                    if (field.type === 'title') {
                        return (
                            <div className="form-field-title">
                                <h3>{field.title}</h3>
                            </div>
                        )
                    } else if (field.type === 'group') {
                        return (
                            <div className={`form-field-wrapper ${field.name}`} key={`${field.label}-${i}`}>
                                <div className="form-label">
                                    {field.label}
                                    {renderTooltip(field)}
                                </div>

                                <div className="form-field-group">
                                    {field.fields.map((f, i) => {
                                        return (
                                            <div key={`form-field-group-${f.name}-${i}`}>
                                                {renderFormField(f)}
                                                {renderFormFieldError(f)}
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )
                    }

                    return (
                        <div className={`form-field-wrapper ${field.name}`} key={field.name || field.uniqKey || i}>
                            {/* field enable/disable toggle switch (eg. for bulk edit) */}
                            { field.canToggle &&
                                    <div className='form-field-toggle'>
                                        {renderFormField(getToggleField(field))}
                                    </div>}

                            <div className="form-label">
                                {field.label} {renderTooltip(field)}
                                {renderFieldDescription(field)}
                            </div>

                            <div className={`form-field ${isFieldError(field) ? 'action-error' : ''}`}>
                                {renderFormField(field)}

                                {renderFieldInputDescription(field)}
                                
                                {/* clear field */}
                                { field.offerClearBtn &&
                                    <div className='form-field-clear'>
                                        <button onClick={(e) => onClearInput(e, field)} disabled={field.readonly}>Clear</button>
                                    </div>}

                                {renderFormFieldError(field)}
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
})

export default FormComponent
