import { FileTypes } from "../../../sys/constants/enums";

export function getGeneralFormFields(
  searchRadiusOptions = [],
  colorSchemesOptions = [],
  subdomain = ''
) {
  return [
    {
      type: 'input-text',
      name: 'name',
      label: 'Name'
    },
    {
      type: 'input-text',
      name: 'subdomain',
      label: 'Subdomain',
      label_info: '(optional)',
      field_info: (
        <div>
          <p>Only letters, numbers, underscores, and hyphens allowed.</p>
          {subdomain && (
            <div className="field-info-section">
              <p>
                <span>Isolated Event Listing:</span>{' '}
                <a
                  target="_blank"
                  href={
                    'https://' +
                    subdomain +
                    '.santasfastpass.com/events/Vvb5cqvx/cherry-hill-programs'
                  }
                >
                  {'https://' +
                    subdomain +
                    '.santasfastpass.com/events/Vvb5cqvx/cherry-hill-programs'}
                </a>
              </p>
              <p>
                <span>Isolated Event Search:</span>{' '}
                <a
                  target="_blank"
                  href={
                    'https://' +
                    subdomain +
                    '.santasfastpass.com/event-search/Vvb5cqvx/development-studio'
                  }
                >
                  {'https://' +
                    subdomain +
                    '.santasfastpass.com/event-search/Vvb5cqvx/development-studio'}
                </a>
              </p>
            </div>
          )}
        </div>
      )
    },
    {
      type: 'select',
      name: 'default_search_radius',
      label: 'Default Search Radius',
      value: '5',
      options: searchRadiusOptions
    },
    {
      type: 'switch',
      name: 'checkout_confirmation_message_required',
      label: 'Checkout Confirmation Message: Required?',
    },
    {
      type: 'editor',
      name: 'checkout_confirmation_message',
      label: 'Checkout Confirmation Message',
    },
    {
      type: 'select',
      name: 'color_scheme_id',
      label: 'Color scheme',
      options: colorSchemesOptions
    }
  ];
}

export function getServicesFormFields() {
  return [
    {
      type: 'input-text',
      name: 'facebook_pixel_id',
      label: 'Meta Pixel',
      field_info: (
        <a
          target="_blank"
          href="https://www.facebook.com/business/tools/meta-pixel/get-started"
        >
          Meta Pixel Documentation
        </a>
      )
    },
    {
      type: 'input-text',
      name: 'tiktok_pixel_id',
      label: 'TikTok Pixel',
      field_info: (
        <a
          target="_blank"
          href="https://ads.tiktok.com/help/article/get-started-pixel"
        >
          TikTok Pixel Documentation
        </a>
      )
    },
    {
      type: 'input-text',
      name: 'ga_tracking_id',
      label: 'Google Analytics Tracking ID',
      field_info: (
        <a target="_blank" href="https://analytics.google.com/analytics/web/">
          Google Analytics Documentation
        </a>
      )
    },
    {
      type: 'input-text',
      name: 'gtm_container_id',
      label: 'Google Tag Manager Container ID',
      field_info: (
        <a
          target="_blank"
          href="https://developers.google.com/tag-manager/quicksatart"
        >
          Google Tag Manager Documentation
        </a>
      )
    },
    {
      type: 'switch',
      name: 'zapier_service_enabled',
      label: 'Zapier?'
    },
    {
      type: 'input-text',
      name: 'zapier_service_url',
      label: 'Zapier: URL',
      field_info:
        'This will change based on the equivalent setting in the following order of precedence: Marketing Plan URL overwrites Market Group URL overwrites Studio URL'
    }
  ];
}

const getImageElement = (image, alt) => {
  if (!image.value) {
    return <img key={0} className="" src={image} alt={alt} />;
  }
};

export function getImagesFormFields(dataImages, inputImages, onDeleteFile) {
  return [
    {
      type: 'input-file',
      name: 'favicon',
      label: 'Favicon',
      label_info: '(Square, ideally 512x512px)',
      acceptedFileTypes: FileTypes.IMAGES,
      field_info: dataImages?.favicon
        ? [
            getImageElement(dataImages.favicon, 'Favicon'),
            <div key={1}>
              <input
                type="checkbox"
                onChange={onDeleteFile}
                name="inputFavicon"
                value={inputImages.inputFavicon}
                checked={inputImages.inputFavicon}
              />
              Delete Image?
            </div>
          ]
        : ''
    },
    {
      type: 'input-file',
      name: 'zip_splash_lg',
      label: 'Zip Splash',
      label_info: '(Large: 780x473px)',
      acceptedFileTypes: FileTypes.IMAGES,
      field_info: dataImages?.zip_splash_lg
        ? [
            getImageElement(dataImages.zip_splash_lg, 'Zip Splash Large'),
            <div key={1}>
              <input
                type="checkbox"
                onChange={onDeleteFile}
                name="inputZipSplashLg"
                value={inputImages.inputZipSplashLg}
                checked={inputImages.inputZipSplashLg}
              />
              Delete Image?
            </div>
          ]
        : ''
    },
    {
      type: 'input-file',
      name: 'zip_splash_md',
      label: 'Zip Splash',
      label_info: '(Medium: 647x473px)',
      acceptedFileTypes: FileTypes.IMAGES,
      field_info: dataImages?.zip_splash_md
        ? [
            getImageElement(dataImages.zip_splash_md, 'Zip Splash Medium'),
            <div key={1}>
              <input
                type="checkbox"
                onChange={onDeleteFile}
                name="inputZipSplashMd"
                value={inputImages.inputZipSplashMd}
                checked={inputImages.inputZipSplashMd}
              />
              Delete Image?
            </div>
          ]
        : ''
    },
    {
      type: 'input-file',
      name: 'zip_splash_sm',
      label: 'Zip Splash',
      label_info: '(Small: 500x473px)',
      acceptedFileTypes: FileTypes.IMAGES,
      field_info: dataImages?.zip_splash_sm
        ? [
            getImageElement(dataImages.zip_splash_sm, 'Zip Splash Small'),
            <div key={1}>
              <input
                type="checkbox"
                onChange={onDeleteFile}
                name="inputZipSplashSm"
                value={inputImages.inputZipSplashSm}
                checked={inputImages.inputZipSplashSm}
              />
              Delete Image?
            </div>
          ]
        : ''
    },
    {
      type: 'input-file',
      name: 'zip_splash_xs',
      label: 'Zip Splash',
      label_info: '(Extra Small: 767px proportional)',
      acceptedFileTypes: FileTypes.IMAGES,
      field_info: dataImages?.zip_splash_xs
        ? [
            getImageElement(dataImages.zip_splash_xs, 'Zip Splash Extra Small'),
            <div key={1}>
              <input
                type="checkbox"
                onChange={onDeleteFile}
                name="inputZipSplashXs"
                value={inputImages.inputZipSplashXs}
                checked={inputImages.inputZipSplashXs}
              />
              Delete Image?
            </div>
          ]
        : ''
    }
  ];
}

export function getCustomCodeFormFields() {
  return [
    {
      type: 'textarea',
      name: 'head_code',
      label: 'Custom Code Block:',
      label_info: 'Head',
      readonly: true,
      field_info: (
        <div>
          <p>Please contact support to make any changes to this attribute</p>
          <p>
            This block of code will appear at the bottom of the &lt;head&gt;
            block.
          </p>
        </div>
      )
    },
    {
      type: 'textarea',
      name: 'body_code',
      label: 'Custom Code Block:',
      label_info: 'Body',
      readonly: true,
      field_info: (
        <div>
          <p>Please contact support to make any changes to this attribute</p>
          <p>
            This block of code will appear at the bottom of the &lt;body&gt;
            block.
          </p>
        </div>
      )
    }
  ];
}
