import UtilsHelper from '../../../helpers/utils';

export function getGeneralFormFields(suggestionsTags = []) {
  return [
    {
      type: 'input-text',
      name: 'name',
      label: 'Name'
    },
    {
      type: 'tags',
      name: 'tags',
      label: 'Tags',
      suggestions: suggestionsTags
    },
    {
      type: 'input-text',
      name: 'code',
      label: 'Code'
    },
    {
      type: 'group',
      label: 'Discount',
      fields: [
        {
          type: 'select',
          name: 'method',
          value: 'flat',
          options: [
            {
              title: '$',
              value: 'flat'
            },
            {
              title: '%',
              value: 'percent'
            }
          ]
        },
        {
          type: 'input-number',
          name: 'value',
          value: 0
        }
      ]
    },
    {
      type: 'switch',
      name: 'waive_fee',
      label: 'Waive Fee?'
    },
    {
      type: 'switch',
      name: 'waive_shipping',
      label: 'Waive Shipping?'
    }
  ];
}

export function getLimitsFormFields() {
  return [
    {
      type: 'switch',
      name: 'limited_guided_mode',
      label: 'Guided Mode Only?'
    },
    {
      type: 'input-number',
      name: 'limit_uses',
      label: 'Uses',
      value: 0
    },
    {
      type: 'input-number',
      name: 'limit_uses_universal',
      label: 'Uses (Universal)',
      value: 0
    },
    {
      type: 'date',
      name: 'limit_range_start_date',
      label: 'Valid From'
    },
    {
      type: 'date',
      name: 'limit_range_end_date',
      label: 'Valid Until'
    },
    {
      type: 'textarea',
      name: 'limit_dates',
      label: 'Reservation Dates',
      label_info: (
        <>
          <p>Please use the following format: mm/dd/yyyy.</p>
          <p>One date per line.</p>
        </>
      ),
      placeholder: 'List of dates, one per line',
      filter: (value) => {
        return UtilsHelper.trimTextareaColumns(value);
      }
    },
    {
      type: 'input-number',
      name: 'limit_min_total',
      label: 'Minimum Purchase Amount',
      field_info: (
        <>
          <p>Package Plan: Order (Sub)Total</p>
          <p>Package/Addon: Line Item Total</p>
        </>
      ),
      value: 0,
      step: 0.05
    },
    {
      type: 'input-number',
      name: 'limit_min_quantity',
      label: 'Miniumum Quantity',
      field_info: 'Ignored on Package Plan (Order)',
      value: 0
    }
  ];
}
