import {useEffect, useState} from 'react';

import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import { format } from 'date-fns'
import dayjs from 'dayjs';

const PickerTime = ({ initialValue, onChange, readOnly=false }) => {
    const [value, setValue] = useState();

    useEffect(() => {
        let valueToSet = initialValue

        if (valueToSet === '') valueToSet = dayjs(null);
        if (valueToSet) valueToSet = dayjs(valueToSet, 'HH:mm:ss').toDate()

        setValue(valueToSet)
    }, [])

    return (
        <div className="picker-date-container">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <TimePicker
                    value={value}
                    disabled={readOnly}
                    onChange={(newValue) => {
                        setValue(newValue)

                        onChange(format(newValue, 'HH:mm:ss'))
                    }}
                    slotProps={{ textField: { placeholder: 'Select Time' } }}
                />
            </LocalizationProvider>
        </div>
    )
}

export default PickerTime
