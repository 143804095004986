import {useState, useEffect, useRef} from 'react'
import {Link} from 'react-router-dom'

import _ from 'lodash'
// import ModalComponent from '../../components/Modal'
import SwitchComponent from '../../components/Switch'
import PageHeaderComponent from '../../components/PageHeader'
import TableComponent from '../../components/Table'
import SelectPaginated from '../../components/SelectPaginated'
import PickerDate from '../../components/PickerDate'

import { ModalComponent } from '../../componentsV2/Complex'

import APIHelper from '../../helpers/api'
import CopyButton from '../../components/CopyButton'
import UtilsHelper from '../../helpers/utils'

import useAppContext from '../../hooks/useAppContext'
import useFetch from '../../hooks/useFetch'
import useInterval from '../../hooks/useInterval';
import useNotices from '../../hooks/useNotices'
import lodash from 'lodash'

import './index.css'
import { OrderStatus, OrderStatusAction, UserPermission } from '../../sys/constants/enums'
import dayjs from 'dayjs'
import RefundModal from '../Order/partials/RefundModal'
import ReactDOM from 'react-dom'
import RescheduleModal from '../Order/partials/RescheduleModal'
import OrderOverviewModal from '../Order/partials/OrderOverviewModal'

const UpcomingOrders = () => {
  const [ac] = useAppContext()

  const { FetchHelper } = useFetch()

  const [NoticeComponent, notices] = useNotices('Orders/UpcomingOrders/Index')

  const [data, setData] = useState([])
  const [dataQRBarcodeModal, setDataQRBarcodeModal] = useState()
  const [dataAppointmentSlotModal, setDataApointmentSlotModal] = useState()
  const [dataRefundOrder, setDataRefundOrder] = useState()
  const [dataRescheduleOrder, setDataRescheduleOrder] = useState()
  const [dataOverviewOrder, setDataOverviewOrder] = useState()

  const [selectedDate, setSelectedDate] = useState(getDate())
  const [selectedMarket, setSelectedMarket] = useState(getMarket())
  const [markets, setMarkets] = useState([])
  const [fetchTimeout, setFetchTimeout] = useState()
  const [disableControls, setDisableControls] = useState()
  const [showSharedExternal, setShowSharedExternal] = useState(true)
  const [countStats, setCountStats] = useState({
    blackedOut: 0,
    available: 0,
    locked: 0,
    pending: 0,
    processed: 0,
    noShow: 0,
    checkedIn: 0,
    total: 0,
    inProgress: 0,
    totalExpectedVisits: 0
  })
  const [shouldAutoRefresh, setShouldAutoRefresh] = useState(true)
  const autoRefreshInterval = useRef()

  useEffect(() => {
    APIHelper.getMarketsGrouped(setMarkets)
  }, [])

  useEffect(() => {
    updateStats()
  }, [data])

  useEffect(() => {
    fetchUpcomingOrders()
  }, [selectedDate, selectedMarket, showSharedExternal])

  useInterval(() => {
    fetchUpcomingOrders();
  }, shouldAutoRefresh ? 60 * 1000 : null);

  function fetchUpcomingOrders() {
    clearTimeout(fetchTimeout)

    if (selectedDate && selectedMarket) {
      setFetchTimeout(setTimeout(() => {
        ac.showSpinner(true)

        FetchHelper({
              url: `/api/v2/admin/orders/upcoming?date=${selectedDate}&market_id=${selectedMarket}&shared=${showSharedExternal}`
          }, (res) => {
              ac.showSpinner(false)
              setData(res.body)
          }, (res) => {
              ac.showSpinner(false)
          })
      }, 500))
    }
  }

  function getDate() {
    let date = UtilsHelper.getLocalStorage('UpcomingOrders_date') || new Date().toISOString().split('T')[0]

    UtilsHelper.setLocalStorage('UpcomingOrders_date', date)

    return date
  }

  function getMarket() {
    return UtilsHelper.getLocalStorage('UpcomingOrders_market') || null
  }

  const handleSelectDate = (newDate) => {
    UtilsHelper.setLocalStorage('UpcomingOrders_date', newDate)
    setSelectedDate(newDate)
  }

  const handleSelectMarket = (option) => {
    const marketId = option.value

    UtilsHelper.setLocalStorage('UpcomingOrders_market', marketId)
    setSelectedMarket(marketId)
  }

  const handlePrevious = () => {
    updateSelectedDate(-1)
  }

  const handleNext = () => {
    updateSelectedDate(1)
  }

  const updateSelectedDate = (distance) => {
    let newDate = new Date(selectedDate)

    newDate.setDate(newDate.getDate() + distance)

    const newDateStr = newDate.toISOString().split('T')[0]

    UtilsHelper.setLocalStorage('UpcomingOrders_date', newDateStr)
    setSelectedDate(newDateStr)
  }

  const setSelectedDateToday = () => {
    const date = new Date()
    const newDateStr = date.toISOString().split('T')[0]

    UtilsHelper.setLocalStorage('UpcomingOrders_date', newDateStr)
    setSelectedDate(newDateStr)
  }

  const updateStats = () => {
    let stats = lodash.mapValues({...countStats}, () => 0)

    lodash.each(data, (tsData) => {
      lodash.each(tsData, (marketData) => {
        let isOffSchedule = (marketData.capacity || 0) === 0
        if (isOffSchedule) stats.offSchedule = (stats.offSchedule || 0) + marketData.data?.length

        let isMarketBlackedOut = marketData.blacked_out
        if (isMarketBlackedOut)
          stats.blackedOut = (stats.blackedOut || 0) + marketData.capacity;

        stats.totalExpectedVisits = (stats.totalExpectedVisits || 0) + marketData.data.length

        lodash.each(marketData.data, (orderData) => {
          // --- update total processed orders amount
          if (orderData.status === OrderStatus.PROCESSED)
            stats.total = Number(stats.total || 0) + Number(orderData.register_total)

          // --- update count stats
          if (!isMarketBlackedOut && !isOffSchedule) {
            switch(orderData.status) {
              case OrderStatus.RESERVATION_LOCKED:
                stats.locked = (stats.locked || 0) + 1
                break
    
              case OrderStatus.COMPLETED:
                stats.pending = (stats.pending || 0) + 1
                break
    
              case OrderStatus.PROCESSED:
                stats.processed = (stats.processed || 0) + 1
                break
    
              case OrderStatus.NO_SHOW:
                stats.noShow = (stats.noShow || 0) + 1
                break
    
              case OrderStatus.CHECKED_IN:
                stats.checkedIn = (stats.checkedIn || 0) + 1
                break
              
              case OrderStatus.PENDING:
                stats.inProgress = (stats.inProgress || 0) + 1
                break
            }
          }
        })

        if (!isMarketBlackedOut && !isOffSchedule)
          stats.available = (stats.available || 0) + (marketData.capacity - marketData.data.length)
      })
    })

    setCountStats(stats)
  }

  const updateRecord = (id, index) => {
    ac.showSpinner(true)
    fetchUpcomingOrders()
  }

  const handleOrderActionProcess = (id) => {
    ac.showSpinner(true)

    FetchHelper({
          method: 'put',
          url: `/api/v2/admin/orders/${id}/status`,
          body: {
            status_action: 'process'
          }
      }, (res) => {
        ac.showSpinner(false)
        updateStats()
      }, (res) => {
        ac.showSpinner(false)
      })
  }

  const handleUnprocess = (id, index) => {
    ac.showSpinner(true)

    FetchHelper({
          method: 'put',
          url: `/api/v2/admin/orders/${id}/status`,
          body: {
            status_action: 'unprocess'
          }
      }, (res) => {
        ac.showSpinner(false)
        updateRecord(id, index)
        updateStats()
      }, (res) => {
        ac.showSpinner(false)
      })
  }

  const handleUnlockReservation = (id, index) => {
    ac.showSpinner(true)

    FetchHelper({
          method: 'put',
          url: `/api/v2/admin/orders/${id}/status`,
          body: {
            status_action: 'unlock_reservation'
          }
      }, (res) => {
        ac.showSpinner(false)
        updateRecord(id, index)
        updateStats()
      }, (res) => {
        ac.showSpinner(false)
      })
  }

  const handleComplete = (id, index) => {
    ac.showSpinner(true)

    FetchHelper({
          method: 'put',
          url: `/api/v2/admin/orders/${id}/status`,
          body: {
            status_action: 'complete'
          }
      }, (res) => {
        ac.showSpinner(false)
        updateRecord(id, index)
        updateStats()
      }, (res) => {
        ac.showSpinner(false)
      })
  }

  const updateStatus = async (orderID, status) => {
    ac.showSpinner(true)
    await UtilsHelper.executePromise(
      FetchHelper({
        method: 'PUT',
        url: `/api/v2/admin/orders/${orderID}/status`,
        body: { status_action: status }
      })
    ) 
    ac.showSpinner(false)

    updateRecord()
    updateStats()
  }

  const handleProcess = (id, index) => {
    ac.showSpinner(true)

    FetchHelper({
          method: 'put',
          url: `/api/v2/admin/orders/${id}/status`,
          body: {
            status_action: 'process'
          }
      }, (res) => {
        ac.showSpinner(false)
        updateRecord(id, index)
        updateStats()
      }, (res) => {
        ac.showSpinner(false)
      })
  }

  const handleLockReservation = (id, index) => {
    ac.showSpinner(true)

    FetchHelper({
          method: 'put',
          url: `/api/v2/admin/orders/${id}/status`,
          body: {
            status_action: 'lock_reservation'
          }
      }, (res) => {
        ac.showSpinner(false)
        updateRecord(id, index)
      }, (res) => {
        ac.showSpinner(false)
      })
  }

  const handleResendEmail = (id, index) => {
    ac.showSpinner(true)

    FetchHelper({
          method: 'post',
          url: `/api/v2/admin/orders/${id}/email`
      }, (res) => {
        ac.showSpinner(false)

        ac.setStatus({
          type: 'success',
          message: 'Email sent!',
          uniqID: UtilsHelper.generateUUID()
        })
      }, (res) => {
        ac.showSpinner(false)
      })
  }

  const handleResendEmailDDL = async (id) => {
    ac.showSpinner(true)
    const [res, err] = await UtilsHelper.executePromise(
      FetchHelper({
        method: 'post',
        url: `/api/v2/admin/orders/${id}/email_ddl`
      })
    )
    ac.showSpinner(false)

    if (res.status >= 200 && res.status < 300)
      ac.setStatus({
        type: 'success',
        message: 'DDL Email sent!',
        uniqID: UtilsHelper.generateUUID()
      })
  }

  const handleResendSMS = (id, index) => {
    ac.showSpinner(true)

    FetchHelper({
          method: 'post',
          url: `/api/v2/admin/orders/${id}/sms`
      }, (res) => {
        ac.showSpinner(false)

        ac.setStatus({
          type: 'success',
          message: 'SMS sent!',
          uniqID: UtilsHelper.generateUUID()
        })
      }, (res) => {
        ac.showSpinner(false)
      })
  }

  const handleResendSMSDDL = async (id) => {
    ac.showSpinner(true)
    const [res, err] = await UtilsHelper.executePromise(
      FetchHelper({
        method: 'post',
        url: `/api/v2/admin/orders/${id}/sms_ddl`
      })
    )
    ac.showSpinner(false)

    if (res.status >= 200 && res.status < 300)
      ac.setStatus({
        type: 'success',
        message: 'DDL SMS sent!',
        uniqID: UtilsHelper.generateUUID()
      })
  }

  const handleRefreshOrders = () => {
    fetchUpcomingOrders()
  }

  const getMarketNameById = (id) => {
    let marketName = 'Market'

    markets.forEach((marketItem, marketIndex) => {
      marketItem.options.forEach((item, index) => {
        if (item.value === id) {
          marketName = item.title
        }
      })
    })

    return marketName
  }

  const handleGenerateWalkup = async () => {
    ac.showSpinner(true);
    const [res] = await UtilsHelper.executePromise(
      FetchHelper({
        url: dataAppointmentSlotModal.walkup_url,
        method: 'POST',
        body: {
          walkup: {
            schedule_id: dataAppointmentSlotModal.schedule_id,
            slot_id: dataAppointmentSlotModal.slot_id
          }
        }
      })
    )
    ac.showSpinner(false);

    setDataApointmentSlotModal()
    if (res?.body) {
      setDataQRBarcodeModal({
        imgSrc: `data:image/svg+xml;base64,${res.body.svg_base64}`,
        text: res.body.url,
        onClose: () => { fetchUpcomingOrders() }
      })
    }
  }

  const renderAvailableSlot = (marketData, timeslot) => {
    if (marketData.blacked_out) {
      return (
        <p className='m-auto py-2'>{'<<<BLACKED OUT>>>'}</p>
      )
    }

    return (
      <button onClick={() => setDataApointmentSlotModal({
          timeslot,
          market_name: marketData.market_name,
          walkup_url: marketData.walkup_url,
          schedule_id: marketData.id,
          slot_id: marketData.slot_id,
        })} className="orders-list-available-button m-2"><i className="bi bi-book"></i> <span>Book {timeslot} for {marketData.market_name}</span></button>
    )
  }

  // --- render time slot row
  const renderTimeSlotRow = (rowData, timeslot) => {
    return <div className="orders-list-row" key={timeslot}>
      <div className="orders-list-row-timeslot">
        {timeslot}
      </div>

      <div className="orders-list-row-rows">
        {lodash.map(rowData, (marketData, marketID) => {
          const ordersData = marketData.data
          let availableSlots = (marketData.capacity || 0) - ordersData.length
          if (availableSlots < 0) availableSlots = 0

          return (
            <div key={marketID} className='market-slots-list'>
              {ordersData ? (
                ordersData.map((orderData, orderIndex) => {
                  // --- prepare container classes
                  let containerClasses = []
                  switch (orderData.status) {
                    case OrderStatus.NO_SHOW:
                      containerClasses.push('no-show')
                      break

                    case OrderStatus.CHECKED_IN:
                      containerClasses.push('checked-in')
                      break

                    case OrderStatus.RESERVATION_LOCKED:
                      containerClasses.push('locked')
                      break

                    case OrderStatus.COMPLETED:
                      containerClasses.push('pending')
                      break
                    
                    case OrderStatus.PENDING:
                      containerClasses.push('available')
                      break
                  }

                  if (marketData.blacked_out)
                    containerClasses = ['blacked-out'] // reset classes

                  if ((marketData.capacity || 0) === 0)
                    containerClasses = ['off-schedule'] // reset classes
                  
                  return (
                    <div className={`orders-list-row-container ${containerClasses}`} key={`${timeslot}_${marketID}_${orderData.id}`}>
                      {renderOrderItem(marketData, orderData, orderIndex)}
                    </div>
                  )
                })
            ) : null}

            {Array(availableSlots).fill(0).map((itm, idx) => {
              // --- prepare container classes
              let containerClasses = ['available']
              if (marketData.blacked_out) {
                containerClasses = containerClasses.filter(c => c !== 'available')
                containerClasses.push('blacked-out')
              }

              return (
                <div key={idx} className={`orders-list-row-container ${containerClasses}`}>
                    {renderAvailableSlot(marketData, timeslot)}
                </div>
              )
            })}
          </div>
          )
        })}
      </div>
    </div>
  }

  // --- render action button (eg. process, unprocess, etc.)
  const renderActionButton = (biIcon, label, onClick) => {
    return (
      <div className='text-center action-button-container' onClick={onClick}>
        <button>
          <i className={`bi ${biIcon}`}></i>
        </button>
        <p className='text-xs'>{label}</p>
      </div>
    )
  }

  const renderActions = (order, i, market) => {
    const orderStatus = order.status
    const hasPermission = ac.AuthHelper.hasPermission
    const isOrderPending = orderStatus === OrderStatus.PENDING

    return (
      <div className="row-controls">
        {isOrderPending && (
          <p>{`EXPIRING ${UtilsHelper.dayjsSafeFormat(order.reservation?.data?.expires_at, 'hh:mm:ss A z', order.reservation?.data?.timezone)}`}</p>
        )}

        {!isOrderPending && (
          <>
            {orderStatus === OrderStatus.PROCESSED && hasPermission(UserPermission.ORDER_PROCESS) && (
              renderActionButton('bi-person-x-fill', 'Unprocess', () => { handleUnprocess(order.id, i) })
            )}

            {orderStatus === OrderStatus.RESERVATION_LOCKED && (
              (hasPermission(UserPermission.ORDER_LOCK_MGMT) && order.use_scheduling) 
                ? renderActionButton('bi-unlock-fill', 'Unlock', () => { handleUnlockReservation(order.id, i) })
                : <span className="message"><em>Locked</em> This order has been locked either manually or because its booking date has passed. To unlock, please seek assistance from a supervisor.</span>
            )}

            {orderStatus === OrderStatus.NO_SHOW && (
              renderActionButton('bi-eye-fill', 'Appeared', () => { updateStatus(order.id, OrderStatusAction.UN_NO_SHOW) })
            )}

            {orderStatus === OrderStatus.CHECKED_IN && (
              renderActionButton('bi-cart-x-fill', 'Requeue', () => { updateStatus(order.id, OrderStatusAction.UN_CHECK_IN) })
            )}

            {orderStatus === OrderStatus.REFUNDED && (
              <span className="message"><em>Refunded</em></span>
            )}

            {orderStatus === OrderStatus.PAID && (
              renderActionButton('bi-bag-check-fill', 'Complete', () => { handleComplete(order.id, i) })
            )}

            {orderStatus === OrderStatus.COMPLETED && (
              <>
                {hasPermission(UserPermission.ORDER_PROCESS) && (
                  renderActionButton('bi-person-check-fill', 'Process', () => { handleProcess(order.id, i) })
                )}

                {hasPermission(UserPermission.ORDER_LOCK_MGMT) && (
                  renderActionButton('bi-lock-fill', 'Lock', () => { handleLockReservation(order.id, i) })
                )}

                {renderActionButton('bi-eye-slash-fill', 'No\nShow', () => { updateStatus(order.id, OrderStatusAction.NO_SHOW) })}

                {renderActionButton('bi-cart-check-fill', 'Check\nin', () => { updateStatus(order.id, OrderStatusAction.CHECK_IN) })}

                {hasPermission(UserPermission.REFUNDING) && order.refundable && (
                  renderActionButton('bi-currency-dollar', 'Refund', () => { setDataRefundOrder(order) })
                )}

                {renderActionButton('bi-calendar-week-fill', 'Reschedule', () => { setDataRescheduleOrder(order) })}

                {renderActionButton('bi-envelope-fill', 'Resend\nEmail', () => { handleResendEmail(order.id, i) })}

                {order.ddl_available && (
                  <>
                    {renderActionButton('bi-envelope-fill', 'Resend\nEmail\nDDL', () => { handleResendEmailDDL(order.id) })}
                    {order.accept_sms && order.messages_ready?.includes('ddl_ready') && (
                      renderActionButton('bi-chat-left-text-fill', 'Resend\nSMS\nDDL', () => { handleResendSMSDDL(order.id) })
                    )}
                  </>
                )}

                {order.accept_sms && order.messages_ready?.includes('order_confirmation') && (
                  renderActionButton('bi-chat-left-text-fill', 'Resend SMS', () => { handleResendSMS(order.id, i) })
                )}
              </>
            )}
          </>
        )}
      </div>
    )
  }

  const renderOrderItem = (marketData, orderData, orderIndex) => {
    const hasPermission = ac.AuthHelper.hasPermission
    const isOrderPending = orderData.status === OrderStatus.PENDING
    const misMatchingProcessedDate = orderData.status === OrderStatus.PROCESSED && 
      !dayjs(orderData.processed_at).isSame(dayjs(orderData?.reservation?.data?.starts_at), 'date')

    const customerPhone = UtilsHelper.bypassAccessText(orderData?.customer?.phone_number);

    return (
      <ul className={`orders-list-row-details`}>
        {/* Options */}
        <li className="orders-list-row-item options">
          {isOrderPending && (
            <p className="text-xs font-bold">IN PROGRESS</p>
          )}

          {orderData.status !== OrderStatus.PENDING && (
            <>
              {/* Blacked out sign */}
              {marketData.blacked_out && <i className="bi bi-calendar2-minus-fill text-red-500"
                title='BLACKED OUT'></i>}

              {/* Off-schedule order */}
              {(marketData.capacity || 0) === 0 && <i className="bi bi-calendar2-x-fill text-red-500"
                title="OVERBOOKED"></i>}

              {/* Processed sign */}
              { misMatchingProcessedDate && orderData.status === OrderStatus.PROCESSED && 
                <i className="bi bi-check-circle-fill text-red-500"
                  title={UtilsHelper.dayjsSafeFormat(orderData.processed_at, '[Processed on] MMM DD [at] hh:mm A z', orderData?.reservation?.data?.timezone)}></i>
              }

              {/* view barcode */}
              <i className="bi bi-qr-code cursor-pointer" title={'View Barcode'}
                onClick={() => { 
                  setDataQRBarcodeModal({ 
                    imgSrc: `data:image/jpeg;base64,${orderData.barcode?.data}`,
                    imgSrcLarge: `data:image/svg+xml;base64,${orderData.barcode?.svg}`,
                    text: orderData.barcode?.text,
                  }) 
                }}></i>

              {/* view order overview */}
              {hasPermission(UserPermission.ORDER_ACCESS) &&
                <i className="bi bi-box cursor-pointer" title={'Order overview'}
                  onClick={() => { setDataOverviewOrder(orderData) }}></i>
              }
            </>
          )}
        </li>

        {/* Confirmation number */}
        <li className="orders-list-row-item confirmation text-xs">
          {orderData.status !== OrderStatus.PENDING && (
            <>
              <span className="mr-2">{orderData.fastpass}</span>
              <CopyButton value={orderData.fastpass} showInput={false} />
            </>
          )}
        </li>

        {/* Market Name */}
        <li className="orders-list-row-item market">{orderData.market_name || marketData.market_name}</li>

        {/* Customer Name */}
        <li className="orders-list-row-item customer">
          {isOrderPending 
            ? 'N/A' 
            : <span style={{overflowWrap: 'anywhere'}}>
                {orderData.customer?.full_name}
                <br/>{orderData.customer?.email}
                {customerPhone && <br/>}
                {customerPhone && customerPhone}
              </span>
            }</li>

        {/* Register */}
        <li className="orders-list-row-item register">
          {isOrderPending ? 'N/A' : UtilsHelper.formatDollar(orderData.register_total)}</li>

        {/* Actions */}
        <li className="orders-list-row-item actions">{renderActions(orderData, orderIndex, marketData)}</li>
      </ul>
    )
  }

  return (
    <div className="upcoming-orders-page-container page-container">
      <PageHeaderComponent title="Upcoming Orders" />

      <NoticeComponent location="Main" notices={notices} />

      {/* QR/Barcode modal */}
      { dataQRBarcodeModal && (
        <ModalComponent
          config={{classNames: 'modal-qr-barcode', fullScreen: dataQRBarcodeModal.enlarge}}
          events={{handleClose: () => {
            if(dataQRBarcodeModal.onClose) dataQRBarcodeModal.onClose()
            setDataQRBarcodeModal()
          }}}>
          <div className={`text-center flex flex-col items-center p-4 ${dataQRBarcodeModal.enlarge ? 'fullScreen' : ''}`}>
            <img className='img-code flex-1' src={dataQRBarcodeModal.enlarge ? (dataQRBarcodeModal.imgSrcLarge || dataQRBarcodeModal.imgSrc) : dataQRBarcodeModal.imgSrc} />
            
            <p className='mt-4'>{dataQRBarcodeModal.text}</p>

            <div className='mt-4 flex justify-center items-center'>
              <p className='mr-2'>Scan Mode: </p>
              <SwitchComponent value={dataQRBarcodeModal.enlarge || false} onChange={(value) => { setDataQRBarcodeModal({...dataQRBarcodeModal, enlarge: value}) }} />
            </div>

            <button className='mt-4' onClick={() => { 
              if(dataQRBarcodeModal.onClose) dataQRBarcodeModal.onClose()
              setDataQRBarcodeModal()
            }}>Ok</button>
          </div>
        </ModalComponent>
      )}

      {dataAppointmentSlotModal ? (
        <ModalComponent events={{handleClose: () => setDataApointmentSlotModal()}}>
          <div className="global-modal">
            <div className="modal-appointment-slot">
              <div className="modal-appointment-slot-title font-bold text-lg">Generate Walkup?</div>
              <div className="modal-appointment-slot-description my-4">
                <p>{dataAppointmentSlotModal.market_name}</p>
                <p className='mt-2'>at</p>
                <p className="mt-2">{dataAppointmentSlotModal.timeslot}</p>
              </div>
              <div className="text-center">
                <button className="secondary" onClick={() => setDataApointmentSlotModal()}>Oops, no thanks!</button>
                <button className='ml-2' onClick={() => {handleGenerateWalkup()}}>Yes, generate!</button>
              </div>
            </div>
          </div>
        </ModalComponent>
      ) : null}

      {/* Refund modal */}
      {dataRefundOrder &&
        ReactDOM.createPortal(
          <RefundModal order={dataRefundOrder} onClose={() => setDataRefundOrder()} />,
          document.getElementById('ui-portals')
        )
      }

      {/* Reschedule/Move modal */}
      {dataRescheduleOrder &&
        ReactDOM.createPortal(
          <RescheduleModal order={dataRescheduleOrder} onClose={() => setDataRescheduleOrder()} />,
          document.getElementById('ui-portals')
        )
      }

      {/* Overview modal */}
      {dataOverviewOrder &&
        ReactDOM.createPortal(
          <OrderOverviewModal order={dataOverviewOrder} onClose={() => setDataOverviewOrder()} />,
          document.getElementById('ui-portals')
        )
      }

      <div className="upcoming-orders-container">
        <div className="row market">
            <label>Market</label>
            <SelectPaginated apiUrl="/api/v2/admin/markets" offerAllOption={false} onSelect={handleSelectMarket} />
        </div>

        <div className="row controls">
          <div>
            <button onClick={handlePrevious}><span>Previous Day</span><i className="bi bi-chevron-left"></i></button>
            <div className="date">
                <PickerDate initialValue={selectedDate} onChange={handleSelectDate} dateFormat="YYYY-MM-DD" />
            </div>
            <button onClick={handleNext}><span>Next Day</span><i className="bi bi-chevron-right"></i></button>
          </div>
        </div>

        <div className="row amount">
          <em>{UtilsHelper.formatDollar(countStats?.total || 0)}</em> in Processed Orders
        </div>

        <div className={ac.isLoading ? 'disabled' : ''}>
          <div className="row stats">
            <ul className='flex flex-wrap justify-center gap-4'>
              <li className="blacked-out"><span className="count blacked-out">{countStats.blackedOut || 0}</span>Blacked Out</li>
              <li className="available"><span className="count available">{countStats.available || 0}</span>Available</li>
              <li className="locked"><span className="count locked">{countStats.locked || 0}</span>Locked</li>
              <li className="pending"><span className="count pending">{countStats.pending || 0}</span>Pending</li>
              <li className="processed"><span className="count processed">{countStats.processed || 0}</span>Processed</li>
              <li className="no-show"><span className="count no-show">{countStats.noShow || 0}</span>No Show</li>
              <li className="checked-in"><span className="count checked-in">{countStats.checkedIn || 0}</span>Checked In</li>
              <li className="off-schedule"><span className="count off-schedule">{countStats.offSchedule || 0}</span>Off Schedule</li>
            </ul>
          </div>

          {/* <div className="row progress">
            <div className="stat blacked-out"></div>
            <div className="stat available"></div>
            <div className="stat locked"></div>
            <div className="stat pending"></div>
          </div> */}

          <div className="row expected-visits">
            <div className="expected-visits-title">
              Total Expected Visits: {countStats.totalExpectedVisits}
            </div>
            <div className="expected-visits-in-progress">
              Includes {countStats.inProgress} orders presently in progress
            </div>
          </div>

          <div className="row secondary-controls">
            <div className="control shared-external-id">
              <div className="title">Show Markets with Shared External ID</div>
              <div><SwitchComponent value={showSharedExternal} onChange={setShowSharedExternal} /></div>
            </div>

            <div className="control refresh">
              <div className="font-bold mb-2">Refresh</div>
              <div>
                <SwitchComponent value={shouldAutoRefresh} onChange={setShouldAutoRefresh} />
              </div>
              <div>{shouldAutoRefresh ? 'Automatic (60s)' : 'Manual'}</div>
              <button onClick={handleRefreshOrders}>
                <i className="bi bi-arrow-clockwise"></i>
              </button>
            </div>
          </div>

          <div className="row orders-list scroll-invisible">
            <div className="orders-list-inner">
              <ul className="orders-list-header">
                <li className="orders-list-row-item timeslot">Timeslot</li>
                <li className="orders-list-row-item options"></li>
                <li className="orders-list-row-item confirmation">Confirmation #</li>
                <li className="orders-list-row-item market">Market</li>
                <li className="orders-list-row-item customer">Customer</li>
                <li className="orders-list-row-item register">Register</li>
                <li className="orders-list-row-item actions">Actions</li>
              </ul>

              {data ? (
                <div className="orders-list-rows">
                  {lodash.map(data, (timeSlotData, timeSlotKey) => 
                    renderTimeSlotRow(timeSlotData, timeSlotKey)
                  )}
                </div>
              ) : (
                <div className="orders-list-rows">
                  No results
                </div>
              )}


            </div>
          </div>

          {/* {data?.length && (<div className="row table">
            <TableComponent columns={tableColumns} data={dataFiltered} onSetPage={setPage} />
          </div>) || null} */}

          {/* {!data?.length && (
            <div className="row no-results">
              No Upcoming Orders
            </div>
          ) || null} */}
        </div>
      </div>
    </div>
  )
}

export default UpcomingOrders
