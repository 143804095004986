import { useState, useEffect } from 'react';

import {
  Button,
  Card, CardContent, CardMedia, CardActionArea, CardActions,
  Typography
} from '@mui/material';

export default function({ order, onClick, elevation = 4 }) {
  return (
    <Card sx={{ width: 350, borderColor: 'primary.main' }} elevation={elevation}>
      <CardActionArea onClick={onClick}>
        <CardMedia
          component="img"
          sx={{ height: order.barcode.height, width: order.barcode.width, mx: 'auto' }}
          image={`data:image/png;base64,${order.barcode.data}`}
          alt={`barcode for ${order.barcode.text}`}
        />
        <CardContent>
          <Typography gutterBottom variant="h6" component="div">
            {order.fastpass} / {order.id}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {order.customer.full_name}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button size="small" color="primary" onClick={onClick}>
          Select
        </Button>
      </CardActions>
    </Card>
  );
};
